let currentTabSelection = '';

const updateCurrentTabSelection = (val) => {
	currentTabSelection = val;
}

export default function() {
	// This doesnt take currentTabSelection into account for multiple tabs on a page

	function attachEventListeners() {
		$('.js-tabs-simple').on('click',
			function(e) {
				showTab($(this).attr('href'));
				setLinkClasses(this);
				updateCurrentTabSelection(e.currentTarget.href.split('#')[1]);
				e.preventDefault();
			});

		updateCurrentTabSelection($('.js-tabs-simple.is-active').attr('href').split('#')[1]); // Need to set value on page init
	}

	function showTab(targetTab) {

		$('.js-tabs-simple-content').removeClass('is-active');
		$('.js-tabs-simple-content').attr("aria-hidden", "true");

		$(targetTab).addClass('is-active');
		$(targetTab).attr("aria-hidden", "false");

		window.site.body.trigger({
			type: 'tabChange',
			tabTarget: targetTab
		});
	}

	function setLinkClasses(link) {
		$('.js-tabs-simple').removeClass('is-active');
		$(link).addClass('is-active');
	}

	attachEventListeners();
}

export { currentTabSelection, updateCurrentTabSelection }
